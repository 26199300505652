<template>
  <div> 
    <b-row class="m-0  justify-content-center">
      <b-col
        class="content-header"
        cols="9"
      >
        <h2 class="content-header-title float-left m-0 pr-1 mr-1">
          {{ $route.meta.pageTitle }}
        </h2>
        <div class="content-header-search mr-1">
          <h3 class="m-0">{{(collaboratorForm.civility==0 ? 'M. ' : collaboratorForm.civility==1 ? 'Mme ' : '') + collaboratorForm.firstName+ ' ' + collaboratorForm.lastName}}</h3>
        </div>
        <div class="content-header-actions d-flex">
            <feather-icon class="cursor-pointer" icon="XIcon" size="24" @click="cancel()" /> 
        </div>
      </b-col>
    </b-row>
    <div v-if="isLoadingCollaborator" class="content-loader-center m-0 h-100">
      <div class="text-center flex-center">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Chargement...</span>
        </div>
        <br />
        Chargement du formulaire...
      </div>
    </div>
    <b-row v-else class="content-scrollable-sticky m-0 h-100 justify-content-center" >
      <!-- <b-col cols="3" class="content-scrollable-sticky-nav">
        <ul>
          <li><a href="#general" class="current">Général</a></li>
          <li><a href="#juridique">Juridique</a></li>
        </ul>
      </b-col> --> 
      <b-col cols="9" class="content-scrollable-sticky-main">

        <validation-observer ref="formCreateCollaborator">
          <b-form autocomplete="off" >
            <b-row id="general" class="my-0">
              <b-col cols="12">
                  <b-row>
                      <b-col cols="4">
                          <b-form-group label="Civilité" label-for="civility">
                              <validation-provider #default="{ errors }" name="Civility">
                                  <v-select id="civility" :state="errors.length &gt; 0 ? false : null" :reduce="(type) =&gt; type.value" v-model="collaboratorForm.civility" :options="optionCivility"></v-select>
                              </validation-provider>
                          </b-form-group>
                      </b-col>
                      <b-col cols="4">
                          <b-form-group label="Nom *" label-for="lastName">
                              <validation-provider #default="{ errors }" name="lastName" rules="required">
                                  <b-form-input id="lastName" v-model="collaboratorForm.lastName" :state="errors.length &gt; 0 ? false : null"></b-form-input><small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                          </b-form-group>
                      </b-col>
                      <b-col cols="4">
                          <b-form-group label="Prénom *" label-for="firstName">
                              <validation-provider #default="{ errors }" name="firstName" rules="required">
                                  <b-form-input id="firstName" v-model="collaboratorForm.firstName" :state="errors.length &gt; 0 ? false : null"></b-form-input><small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                          </b-form-group>
                      </b-col>
                  </b-row>
              </b-col>
              <b-col cols="12">
                  <b-row>
                      <b-col cols="4">
                          <b-form-group label="Email" label-for="email">
                              <validation-provider #default="{ errors }" name="email" rules="email">
                                  <b-form-input id="email" v-model="collaboratorForm.email" :state="errors.length &gt; 0 ? false : null"></b-form-input><small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                          </b-form-group>
                      </b-col>
                      <b-col cols="4">
                          <b-form-group label="Téléphone" label-for="phoneNumber">
                              <validation-provider #default="{ errors }" name="Phone">
                                  <b-form-input id="phoneNumber" v-model="collaboratorForm.phoneNumber" v-mask="'## ## ## ## ##'" :state="errors.length &gt; 0 ? false : null"></b-form-input>
                              </validation-provider>
                          </b-form-group>
                      </b-col>
                      <b-col cols="4">
                          <b-form-group label="Téléphone secondaire" label-for="secondaryPhoneNumber">
                              <validation-provider #default="{ errors }" name="secondaryPhoneNumber">
                                  <b-form-input id="secondaryPhoneNumber" v-model="collaboratorForm.secondaryPhoneNumber" v-mask="'## ## ## ## ##'" :state="errors.length &gt; 0 ? false : null"></b-form-input>
                              </validation-provider>
                          </b-form-group>
                      </b-col>
                  </b-row>
              </b-col>
              <b-col cols="12">
                  <b-row>
                      <b-col cols="4">
                          <b-form-group label="Métier *" label-for="jobId">
                              <validation-provider #default="{ errors }" name="JobId" rules="required">
                                  <v-select id="jobId" :state="errors.length &gt; 0 ? false : null" :reduce="(type) =&gt; type.id" v-model="collaboratorForm.jobId" :options="jobsList"><template v-slot:no-options=""><template> Aucun r&eacute;sultat trouv&eacute; </template></template>
                                      <!-- <li class="border-top p-1" slot="list-footer">
                                          <b-button class="w-100" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" v-b-modal.modal-create-job="v-b-modal.modal-create-job" @click="popupCreateJobEvent('')">+ Nouveau m&eacute;tier</b-button>
                                      </li> -->
                                  </v-select><small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                          </b-form-group>
                      </b-col>
                      <b-col cols="4">
                          <b-form-group label="Type de contrat *" label-for="contractId">
                              <validation-provider #default="{ errors }" name="ContractRef" rules="required">
                                  <v-select id="contractId" :state="errors.length &gt; 0 ? false : null" :reduce="(type) =&gt; type.id" v-model="collaboratorForm.contractId" :options="contractsList"><template v-slot:no-options=""><template> Aucun r&eacute;sultat trouv&eacute; </template></template>
                                      <!-- <li class="border-top p-1" slot="list-footer">
                                          <b-button class="w-100" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" v-b-modal.modal-create-contract="v-b-modal.modal-create-contract" @click="popupCreateContractEvent('')">+ Nouveau contrat</b-button>
                                      </li> -->
                                  </v-select><small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                          </b-form-group>
                      </b-col>
                      <b-col cols="4">
                          <b-form-group label="Matricule" label-for="matricule">
                              <validation-provider #default="{ errors }" name="Matricule">
                                  <b-form-input id="matricule" v-model="collaboratorForm.matricule" :state="errors.length &gt; 0 ? false : null"></b-form-input>
                              </validation-provider>
                          </b-form-group>
                      </b-col>
                  </b-row>
              </b-col>
              <b-col cols="12">
                  <b-row>
                      <b-col cols="12">
                          <b-form-group label="Commentaire" label-for="commentaire">
                              <vs-textarea v-model="collaboratorForm.comments" height="140px" style="min-height:140px; font-size:1rem"></vs-textarea>
                          </b-form-group>
                      </b-col>
                  </b-row>
              </b-col> 
              <b-col cols="12"><small class="cursor-pointer text-primary" @click="displayFormComplement">{{ displayMore ? '- Masquer les informations complémentaires' : '+ Afficher les informations complémentaires' }} </small></b-col>
            </b-row>
            <b-row id="complement" class="my-0" v-if="displayMore">
              <b-col cols="12">
                  <b-form-group label-for="address">
                      <template slot="label">
                          <div class="d-flex w-100 justify-content-between">
                              <label for="address'">Adresse</label>
                          </div>
                      </template>
                      <validation-provider #default="{ errors }" name="Address">
                          <vue-typeahead-bootstrap
                            type="text"
                            :state="errors.length &gt; 0 ? false : null"
                            class="mb-1 not-autocomplete" id="address"
                            v-model="collaboratorForm.address"
                            :ieCloseFix="false"
                            :data="autocompleteAddressData"
                            :serializer="item => item.properties.label"
                            :showAllResults="true"
                            placeholder="Rechercher une adresse ou saisissez : Rue et n° de rue"
                            @input="autocompleteAddressSource()"
                            @hit="autocompleteAddressSelected"
                            autocomplete="nope" aria-autocomplete="nope"
                          >
                            <template slot="suggestion" slot-scope="{ data, htmlText }">
                              <div class="item"> 
                                <small>{{data.properties.name}}</small> 
                                <span>{{data.properties.postcode+ ' ' + data.properties.city}}</span> 
                              </div>
                            </template>
                          </vue-typeahead-bootstrap>
                      </validation-provider>
                      <validation-provider #default="{ errors }" name="addressComplement">
                          <b-form-input class="mb-1" id="addressComplement" v-model="collaboratorForm.addressComplement" :state="errors.length &gt; 0 ? false : null" placeholder="Bâtiment, Appartement.."></b-form-input>
                      </validation-provider>
                      <b-row>
                          <b-col cols="4">
                              <validation-provider #default="{ errors }" name="zipcode">
                                  <b-form-input class="mb-1" id="zipcode" v-model="collaboratorForm.zipCode" :state="errors.length &gt; 0 ? false : null" placeholder="Code postal"><small class="text-danger">{{ errors[0] }}</small></b-form-input>
                              </validation-provider>
                          </b-col>
                          <b-col cols="8">
                              <validation-provider #default="{ errors }" name="city">
                                  <b-form-input class="mb-1" id="city" v-model="collaboratorForm.city" :state="errors.length &gt; 0 ? false : null" placeholder="Ville"><small class="text-danger">{{ errors[0] }}</small></b-form-input>
                              </validation-provider>
                          </b-col>
                      </b-row>
                      <validation-provider #default="{ errors }" name="country">
                          <b-form-input class="mb-1" id="country" v-model="collaboratorForm.country" :state="errors.length &gt; 0 ? false : null" placeholder="Pays"><small class="text-danger">{{ errors[0] }}</small></b-form-input>
                      </validation-provider>
                  </b-form-group>
                  
                  <b-row>
                    <b-col cols="3">
                        <validation-provider name="latitude">
                            <b-form-input class="mb-0" id="latitude" v-model="collaboratorForm.latitude" placeholder="Latitude"></b-form-input>
                        </validation-provider>
                    </b-col>
                    <b-col cols="3">
                        <validation-provider name="longitude">
                            <b-form-input class="mb-0" id="longitude" v-model="collaboratorForm.longitude" placeholder="Longitude"></b-form-input>
                        </validation-provider>
                    </b-col>
                  </b-row>  
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
        <b-row class="my-0">
          <b-col cols="12" class="py-0">
            <div
              class="d-flex justify-content-between mt-2"
              style="border-top: 1px solid #e2e2e2; padding-top: 0.5rem;"
            >
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  @click="cancel()"
                >
                  <feather-icon icon="ArrowLeftIcon" />
                  Annuler
                </b-button>
                <b-button
                  v-if="collaboratorForm.id"
                  variant="outline-danger"
                  class="btn-icon ml-2"
                  @click="archiveCollaboratorLocal(collaboratorForm.id, collaboratorForm.firstName+' '+collaboratorForm.lastName)"
                >
                    <feather-icon icon="ArchiveIcon" /> 
                </b-button>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="create"
              >
                <feather-icon icon="SaveIcon" v-if="collaboratorForm.id" />
                <feather-icon icon="PlusIcon" v-else />
                {{ collaboratorForm.id ? 'Modifier' : 'Ajouter' }}
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>  


    
  </div>
</template>
<script>
import {debounce} from 'lodash';
import Autocomplete from 'vuejs-auto-complete'
import vSelect from "vue-select";
import { ECivility } from "@/types/api-orisis/enums/enums";
import Ripple from "vue-ripple-directive";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";


configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

import { mapGetters, mapActions } from "vuex";
import { BFormCheckbox, BFormSpinbutton } from "bootstrap-vue";
import { mask } from "vue-the-mask";


export default {
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      scrolled: false,
      autocompleteCollaboratorName:"", 
      autocompleteCollaboratorNameData:[],
      autocompleteAddressData:[],
      autocompleteAddressIndex:0,
      optionCivility: [
        {
          label: ECivility[ECivility.Monsieur],
          value: ECivility.Monsieur,
        },
        {
          label: ECivility[ECivility.Madame],
          value: ECivility.Madame,
        },
        {
          label: ECivility[ECivility.Autre],
          value: ECivility.Autre,
        },
      ],
      required,
      url,
      email,
      displayMore: false,
      dataOrigine:{},
      collaboratorForm:{},
      collaboratorType:{}
    }
  },
  created(){
    this.popupCollaboratorEvent(this.id)
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true)
    this.deteleAutocomplete();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true)
  },
  methods: {
    ...mapActions([
      "createCollaborator",
      "fetchContactsList",
      "fetchCollaborator",
	    "updateCollaborator",
      "archiveCollaborator",
      "fetchContractsList",
      "fetchJobsList"
    ]),
    onScroll(e) {
      let mainNavLinks = document.querySelectorAll(".content-scrollable-sticky-nav ul li a");
      let fromTop = e.target.scrollTop;

      mainNavLinks.forEach(link => {
        let section = document.querySelector(link.hash);
        if (
          section.offsetTop <= fromTop &&
          section.offsetTop + section.offsetHeight > fromTop
        ) {
          link.classList.add("current");
        } else {
          link.classList.remove("current");
        }
      });
    },
    deteleAutocomplete(){
      let elements = document.querySelectorAll('.not-autocomplete input');
      if (!elements) {
        return;
      }
      elements.forEach(element => {
        element.setAttribute("autocomplete", "nope");

      });
    },
    popupCollaboratorEvent(id) { 
      this.fetchJobsList();
      this.fetchContractsList();
      if (id != '') {
        this.fetchCollaborator(id).then(res => {
          this.collaboratorForm = res
          this.collaboratorForm.jobId = res.job.id
          this.collaboratorForm.contractId = res.contract.id
          this.dataOrigine=JSON.stringify(this.collaboratorForm)
        })
      }else{
        this.initializeForm()
      }
    },
    initializeForm () {
      this.collaboratorForm = {
        id: 0,
        lastName: '',
        firstName: '',
        email: '',
        phoneNumber: '',
        secondaryPhoneNumber: '',
        jobId: null,
        contractId: null,
        matricule: null,
        comments: '',
        address: '',
        addressComplement: '',
        zipCode: '',
        city: '',
        country: '',
        socialSecurityNumber: '',
        travelMax: 0,
        latitude: null,
        longitude: null
      }
      this.dataOrigine=JSON.stringify(this.collaboratorForm)
    },
    displayFormComplement(){
      this.displayMore = !this.displayMore
      this.$nextTick(() => {
        this.deteleAutocomplete()
      })
    },
    create (e) {
      e.preventDefault()
      this.$refs.formCreateCollaborator
        .validate() 
        .then(success1 => {
          if (success1) {
            if (this.collaboratorForm.id) 
              this.updateCollaborator(this.collaboratorForm)
            else 
              this.createCollaborator(this.collaboratorForm)
            
            this.$nextTick(() => {
              this.$tabs.close({to : "/collaborators"})
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },

    // Autocomplete Address from data.gouv
    autocompleteAddressSource: debounce(function(indexSource){
      let _this = this
      fetch(`https://api-adresse.data.gouv.fr/search/?q=${this.collaboratorForm.address}&autocomplete=0`)
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.autocompleteAddressData = data.features;
          this.autocompleteAddressIndex = indexSource
          console.log(this.autocompleteAddressData)
        })
    }, 500),
    autocompleteAddressSelected (item) {
      console.log("autocompleteAddressSelected", item)
      this.collaboratorForm.address=item.properties.name
      this.collaboratorForm.city=item.properties.city
      this.collaboratorForm.zipCode=item.properties.postcode 
      this.collaboratorForm.latitude=item.geometry.coordinates[1] 
      this.collaboratorForm.longitude=item.geometry.coordinates[0]
    },

    // Controls from
    archiveCollaboratorLocal (id, label) {
      this.$bvModal
        .msgBoxConfirm('Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.', {
          title:
            'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Archiver',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.archiveCollaborator(id);
            this.$tabs.close()
          }
        })
    },
    cancel(){
      console.log("cancel", this.$tabs)
      console.log(this.dataOrigine)
      console.log(JSON.stringify(this.collaboratorForm))
      if(this.dataOrigine==JSON.stringify(this.collaboratorForm)){
        this.$tabs.close()
      }else{
        this.$bvModal
        .msgBoxConfirm('Cette action est définitive et irréversible.', {
          title:
            'Êtes-vous sûr de vouloir quitter l\'édition sans enregistrer ?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Oui',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
           this.$tabs.close()
          }
        })
      }
    },
  },
  computed: {
    ...mapGetters([
      "isLoadingUser",
      "usersList",
      "isLoadingContract",
      "contractsList",
      "isLoadingJob",
      "jobsList",
      "isLoadingCollaborator",
    ])
  },
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    BFormSpinbutton,
    Autocomplete,
  },
  directives: {
    Ripple,
    mask
  }
}
</script>
<style lang="scss">
.content-scrollable-sticky{
  // display: grid; 
  // grid-template-columns: min-content 1fr; 
  position:relative;
  .content-scrollable-sticky-nav{
    white-space: nowrap;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li a {
        display: block;
        padding: 1rem 0rem;
        color: white;
        text-decoration: none;
      }
      li a.current {
        background: black;
      }
    }
  }
}

/* Only stick if you can fit */
@media (min-height: 300px) {
  .content-scrollable-sticky-nav ul {
    position: sticky;
    top: 0;
  }
}
</style>